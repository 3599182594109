import gql from 'graphql-tag'

export const HISTORY_FRAGMENT = gql`
  fragment History on Version {
    id
    changeset {
      field
      current
      previous
      type
    }
    event
    meta
    statusChange
    userName
    createdAt
    itemType
    itemId
    financeStatusChange
    financeRequestJson
    financeRequestError
    order {
      creditType
      creditReason {
        code
        description
      }
      creditTotalAmount
      creditCurrencyCode
      debitTotalAmount
      debitCurrencyCode
      debitReason {
        code
        description
      }
      invoiceTotalAmount
      invoiceCurrencyCode
      invoiceNumber
      sapSalesOrderNumber
      sapCreditRequestNumber
      sapCreditMemoNumber
      rebillTotalAmount
      rebillCurrencyCode
      sapRebillTotalAmount
      sapRebillCurrencyCode
      sapTotalPrice
      sapCurrencyCode
      sapDebitRequestNumber
      sapDebitMemoNumber
      sapPriceType
      s4OrderCreationFailedReason
      s4OrderUpdateFailedReason
      s4SalesOrderCreditCheckErrorMessage
      sapRouting
    }
  }
`
