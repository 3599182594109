import gql from 'graphql-tag'

export const DEPOT_QUERY = gql`
  query Depot($id: ID!) {
    depot(id: $id) {
      name
      primaryCutOff
      secondaryCutOff
      line1
      line2
      line3
      zip
      city
      state
      country
      shippingGroups {
        amTreatmentDeliveryCutOff
        minimumShippingTimeline
        preferredShippingTimeline
        sameDayShippingAvailable
      }
    }
}
`