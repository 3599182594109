import gql from 'graphql-tag'
import { FULL_ORDER_FRAGMENT } from '../fragments/OrderFragments'

export const FULL_TREATMENT_PLAN_FRAGMENT = gql`
  fragment FullTreatmentPlan on TreatmentPlan {
    __typename
    id
    reference
    treatmentDate
    treatmentTime
    treatmentSource
    poNumber
    shipDate
    specialDeal
    alertData
    deliveryDate
    deliveryTime
    notForSap
    sapBillingCode
    invoiceNotes
    treatmentReference
    treatingPhysicianId
    treatmentIntent
    billingBlock
    createdAt
    customer {
      __typename
      id
      name
      country
      nrTsNumbers
      nordionAccountNumber
      alertData
      specialDeal
    }
    address {
      id
      city
      country
      freightTerms
      incoterms
      incotermsLocation
      incotermsYear
      line1
      line2
      line3
      nordionShipToId
      primary
      state
      zip
    }
      orders {
        ...FullOrder
      }
  }
  ${FULL_ORDER_FRAGMENT}`