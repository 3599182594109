import gql from 'graphql-tag'
import {
  FULL_INVENTORY_TRANSFER_FRAGMENT
} from '../fragments'

export const INVENTORY_TRANSFERS_INDEX_QUERY = gql`
  query inventoryTransfers($calibrationDate: Date!) {
    inventoryTransfers(calibrationDate: $calibrationDate) {
      id
      status
      createdAt
      shipDate
      calibrationDate
      inventoryTransferNumber
      regionCode
      numberOfDoses
      expectedArrivalDate
      address {
        id
        depot
      }
      delinkedBuildPlan {
        id
      }
    }
  }
`

export const INVENTORY_TRANSFER_SHOW_QUERY = gql`
  query GetInventoryTransfer($id: ID!) {
    inventoryTransfer(id: $id) {
      ...FullInventoryTransfer
    }
  }
  ${FULL_INVENTORY_TRANSFER_FRAGMENT}
`

export const INVENTORY_TRANSFER_NEW_QUERY = gql`
  query NewInventoryTransfer($calibrationDate: String!) {
    newInventoryTransfer(calibrationDate: $calibrationDate) {
      address {
        id
        city
        country
        line1
        line2
        line3
        nordionShipToId
        state
        zip
        mnxDepot
        depot
      }
      addresses {
        id
        city
        country
        line1
        line2
        line3
        nordionShipToId
        state
        zip
        mnxDepot
        depot
      }
      inventoryTransferOrders {
        id
        status
        regionCode
        doseSize
        updatedAt
        createdAt
        calibrationDate
      }
      availableDoses {
        id
        status
        regionCode
        doseSize
        updatedAt
        createdAt
        calibrationDate
      }
      id
      status
      createdAt
      shipDate
      calibrationDate
      inventoryTransferNumber
      expectedArrivalDate
      expectedArrivalTime
      regionCode
      numberOfDoses
    }
  }
`

export const INVENTORY_TRANSFER_UPDATE_QUERY = gql`
  query UpdateInventoryTransfer($id: ID!, $fieldName: String!, $value: Date!) {
    updateInventoryTransfer(id: $id, fieldName: $fieldName, value: $value)
  }
`

export const INVENTORY_TRANSFER_ORDER_SHOW_QUERY = gql`
  query GetInventoryTransferOrder($id: ID!) {
    inventoryTransfer(id: $id) {
      ...FullInventoryTransfer
      inventoryTransferOrders {
        status
        orderNumber
        doseSize
      }
    }
  }
`
