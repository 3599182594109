export const convertToAmPm = (timeStr: string|null) => {
  // Split the input string into hours and minutes
  const [hours, minutes] = (timeStr || '').split(':').map(Number);

  // Determine whether it's A.M. or P.M.
  const period = hours >= 12 ? 'PM' : 'AM';

  // Convert hours to 12-hour format
  const adjustedHours = hours % 12 || 12;

  // Return the formatted time
  return `${adjustedHours}:${minutes.toString().padStart(2, '0')} ${period}`;
}