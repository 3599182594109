import gql from 'graphql-tag'

export const ONLY_INVENTORY_TRANSFER_ORDER_FRAGMENT = gql`
  fragment OnlyInventoryTransferOrder on InventoryTransferOrders {
      id
      orderNumber
      status
      regionCode
      doseSize
      carrier
      waybillNumber
      packageNumber
      activityMeasure
      transportIndex
      lotNumber
      shipDate
      calibrationDate
      nordionShipId
      expectedInboundDatetime
      mnxAsnNumber
      expectedArrivalDate
      mnxAsnRequest {
        requestStatus
      }
      updatedAt
      createdAt
    }
  `


export const FULL_INVENTORY_TRANSFER_FRAGMENT = gql`
  fragment FullInventoryTransfer on InventoryTransfer {
    address {
      id
      city
      country
      line1
      line2
      line3
      nordionShipToId
      state
      zip
      depot
    }
    inventoryTransferOrders {
      id
      orderNumber
      status
      nordionRef
      packageNumber
      activityMeasure
      lotNumber
      transportIndex
      regionCode
      doseSize
      carrier
      waybillNumber
      packageNumber
      activityMeasure
      transportIndex
      lotNumber
      shipDate
      calibrationDate
      nordionShipId
      nordionRef
      expectedInboundDatetime
      statusBeforeCancelling
      mnxAsnNumber
      mnxAsnRequest {
        requestStatus
      }
      nordionRef
      updatedAt
      createdAt
      surplusStatus
      expectedArrivalDate
      expectedArrivalTime
    }
    allottedDoses {
      id
      inventoryTransferOrderNumber
      status
      regionCode
      doseSize
      updatedAt
      createdAt
    }
    availableDoses {
      id
      status
      regionCode
      doseSize
      updatedAt
      createdAt
      calibrationDate
    }
    id
    status
    createdAt
    shipDate
    calibrationDate
    inventoryTransferNumber
    expectedArrivalDate
    expectedArrivalTime
    regionCode
    numberOfDoses
  }
`

export const NEW_INVENTORY_TRANSFER_FRAGMENT = gql`
  fragment NewInventoryTransfer on InventoryTransfer {
    address {
      id
      city
      country
      line1
      line2
      line3
      nordionShipToId
      state
      zip
    }
    id
    status
    createdAt
    shipDate
    calibrationDate
    inventoryTransferNumber
    regionCode
    numberOfDoses
    expectedArrivalDate
    expectedArrivalTime
  }
`

export const INVENTORY_TRANSFER_ORDER_CHANGE_FRAGMENT = gql`
  fragment InventoryTransferOrderChange on InventoryTransfer {
    __typename
    allottedDoses {
      id
      inventoryTransferOrderNumber
      status
      regionCode
      doseSize
      updatedAt
      createdAt
    }
    availableDoses {
      id
      status
      regionCode
      doseSize
      updatedAt
      createdAt
      calibrationDate
    }
    id
    status
    createdAt
    shipDate
    calibrationDate
    inventoryTransferNumber
    regionCode
    numberOfDoses
    expectedArrivalDate
  }
`

export const INVENTORY_TRANSFER_ORDER_FRAGMENT = gql`
  fragment InventoryTransferOrderCreate on InventoryTransfer {
    __typename
    allottedDoses {
      id
      inventoryTransferOrderNumber
      status
      regionCode
      doseSize
      updatedAt
      createdAt
    }
    id
    status
    createdAt
    shipDate
    calibrationDate
    inventoryTransferNumber
    regionCode
    numberOfDoses
    expectedArrivalDate
  }
`
