import gql from 'graphql-tag'
import { FULL_TREATMENT_PLAN_FRAGMENT } from '../fragments'

export const TREATMENTS_INDEX_QUERY = gql`
  query TreatmentsIndex(
    $search: String
    $sort: SortInput
    $page: Int
    $limit: Int
    $countries: [String!]
  ) {
    treatmentPlans(
      search: $search
      sort: $sort
      page: $page
      limit: $limit
      countries: $countries
    ) {
      totalPages
      items {
        __typename
        id
        reference
        shipDate
        treatmentDate
        customer {
          __typename
          id
          name
          country
        }
      }
    }
  }
`

export const TREATMENTS_PLAN_QUERY = gql`
  query TreatmentPlan($id: ID!) {
    treatmentPlan(id: $id) {
      ...FullTreatmentPlan
    }
}
${FULL_TREATMENT_PLAN_FRAGMENT}
`

export const TREATMENT_PLAN_FULFILMENT_QUERY = gql`
  query TreatmentPlanFulfillments(
    $customerId: ID!
    $addressId: ID!
    $treatmentDate: Date!
    $treatmentTime: String!
    $doses: [TreatmentPlanFulfillmentDose!]!
  ) {
    treatmentPlanFulfillments(
      customerId: $customerId
      addressId: $addressId
      treatmentDate: $treatmentDate
      treatmentTime: $treatmentTime
      doses: $doses
    ) {
      shipDate
      deliveryDate
      deliveryTime
      doses {
        calibrationDate
        dosage
        available
        inventorySource
      }
    }
  }
`
