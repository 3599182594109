import gql from 'graphql-tag'
import { FULL_ORDER_FRAGMENT, TREATMENT_FRAGMENT } from '../fragments'

export const ORDERS_INDEX_QUERY = gql`
  query OrdersIndex(
    $search: String
    $status: OrderStatusEnum
    $financeStatus: FinanceStatusEnum
    $sort: SortInput
    $page: Int
    $limit: Int
    $poBlank: Boolean
    $countries: [String!]
  ) {
    orders(
      search: $search
      status: $status
      financeStatus: $financeStatus
      sort: $sort
      page: $page
      limit: $limit
      poBlank: $poBlank
      countries: $countries
    ) {
      totalPages
      items {
        __typename
        id
        orderNumber
        orderDate
        status
        buildPlan {
          __typename
          id
          buildNumber
          status
        }
        customer {
          __typename
          id
          name
          country
        }
        treatmentDate
        shipDate
        calibrationDate
        doses {
          dosage
          quantity
        }
        hasAccessories
        hasStandardDeliveryCharge
        hasExpeditedDeliveryCharge
        orderCreator {
          __typename
          id
          firstName
          lastName
          displayName
          name
          isTopUser
          hoverText
        }
        sameDayShipping
        usingSurplus
        usingMnxSurplus
        usingMnxCaSurplus
        usingBwxtSurplus
        usingMnxReservedSurplusInventory
        potentialDuplicate
        financeStatus
      }
    }
  }
`

export const AT_RISK_ORDERS_INDEX_QUERY = gql`
  query AtRiskOrders(
    $sort: SortInput
    $page: Int
    $limit: Int
  ) {
    atRiskOrders(
      sort: $sort
      page: $page
      limit: $limit
    ) {
      totalPages
      items {
        __typename
        id
        orderNumber
        orderDate
        status
        customer {
          __typename
          id
          name
        }
        region {
          __typename
          id
          code
        }
        treatmentDate
        shipDate
        calibrationDate
        doses {
          dosage
          quantity
        }
        hasAccessories
        hasStandardDeliveryCharge
        hasExpeditedDeliveryCharge
        usingSurplus
        usingBwxtSurplus
        sameDayShipping
      }
    }
  }
`

// export const POTENTIAL_ORDERS_QUERY = gql`
//   query PotentialOrders($calibrationDate: Date!, $page: Int, $limit: Int) {
//     potentialOrders(
//       calibrationDate: $calibrationDate
//       page: $page
//       limit: $limit
//     ) {
//       totalPages
//       items {
//         id
//         orderNumber
//         status
//         shipDate
//         regionCode
//         doses {
//           dosage
//           quantity
//         }
//       }
//     }
//   }
// `

export const BUMPABLE_ORDERS_COUNT_QUERY = gql`
  query BumpableCountQuery(
    $calibrationDate: Date!
    $regionCode: RegionCodeEnum!
    $dosages: [Float!]!
  ) {
    bumpableCount(
      calibrationDate: $calibrationDate
      regionCode: $regionCode
      dosages: $dosages
    )
  }
`

export const BUMPABLE_ORDERS_QUERY = gql`
  query BumpableOrders(
    $calibrationDate: Date!
    $regionCode: RegionCodeEnum!
    $dosages: [Float!]!
  ) {
    bumpableOrders(
      calibrationDate: $calibrationDate
      regionCode: $regionCode
      dosages: $dosages
    ) {
      id
      orderNumber
      shipDate
      regionCode
      treatmentIds
      doses {
        dosage
        quantity
      }
    }
  }
`

export const ORDERS_SHOW_QUERY = gql`
  query GetOrder($id: ID!) {
    order(id: $id) {
      ...FullOrder
    }
  }
  ${FULL_ORDER_FRAGMENT}
`

export const ORDERS_TREATMENTS_SHOW_QUERY = gql`
  query GetOrderTreatments($id: ID!) {
    order(id: $id) {
      id
      orderNumber
      status
      usingSurplus
      usingMnxSurplus
      usingMnxCaSurplus
      usingMnxReservedSurplusInventory
      treatments {
        ...Treatment
      }
    }
  }
  ${TREATMENT_FRAGMENT}
`

export const MOVEABLE_ORDERS_QUERY = gql`
  query MoveableOrders(
    $calibrationDate: Date!
    $regionCode: RegionCodeEnum!
    $dosage: Float!
    $surplusType: SurplusEntryTypeEnum!
    $sourceType: String!
  ) {
    moveableOrders(
      calibrationDate: $calibrationDate
      regionCode: $regionCode
      dosage: $dosage
      surplusType: $surplusType
      sourceType: $sourceType
    ) {
      id
      sourceType
      orderNumber
      cancelledAt
      surplusAvailableAt
      surplusType
      doses {
        dosage
        quantity
      }
    }
  }
`
